
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
  background: #f5f6f5;
  .framePage-article {
    background-color: #fff;
    margin-right: 0.675rem;
    height: 100%;
    padding: 1rem 0.675rem;
    box-sizing: border-box;
    width: 25%;
    min-width: 25rem;
    .paperObj /deep/.el-form-item__content {
      margin-left: 0 !important;
    }
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        display: flex;
        justify-content: space-between;
        .number {
          padding-left: 1rem;
        }
        img {
          width: 100px;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: auto;
          display: flex;
          align-items: center;
          .qbimg {
            width: 5rem;
          }
        }
      }
    }
  }
}
